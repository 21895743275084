@import "../../../../assets/scss/shared/index";

.event-attach-page {
  width: 810px;
  max-width: 100%;
}

.event-attach-page__field {
  margin-bottom: 20px;
}

.event-attach-page__title {
  margin-bottom: 33px;
}

.event-attach-page__panel {
  display: flex;
  justify-content: space-between;
  max-width: 460px;
  margin-left: auto;
  flex-wrap: wrap;

  @include xs() {
    grid-gap: 20px;
    justify-content: center;
  }
}
