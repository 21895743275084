@import "../../../assets/scss/shared/index";

.modal-complete-test {
  width: 460px;
  max-width: 100%;
  text-align: center;
}

.modal-complete-test__panel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 25px;
  grid-gap: 20px;
}

.modal-complete-test__fields {
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @include less(min) {
    transform: scale(1.1);
  }
}

.modal-complete-test__desc {
  width: 100%;
  margin: 0 20px 18px 0;
}

.modal-complete-test__confirm {
  --el-fonts--text-color: #{$label-color};

  @include less() {
    text-align: left;
  }
}
