@import "../../assets/scss/shared/index";

[class*=cover--] {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  text-align: left;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  @include less() {
    flex-direction: column-reverse;
    align-items: flex-start;
    grid-gap: 10px;
  }

  label {
    @include font($text-color 16px 21px 500);
    width: #{'min(160px, 100%)'};
    padding-right: 15px;
  }

  span {
    @include font($accent-color 16px 21px 700);
    margin: 10px 0 -10px;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.cover--primary__polygon {
  flex: 1 1 #{'min(290px, calc(100% - 160px))'};
  border-radius: $block-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 260px;
  background-color: $input-bg-color;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05);

  label {
    margin-right: auto;
  }

  .text--md {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: $action-color;
    padding: 10px;
    border-radius: $block-radius;
  }

  > img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
  }
}

.cover--mini__polygon {
  width: 220px;
  border-radius: $block-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 125px;
  background-color: $input-bg-color;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05);
  margin-right: auto;

  svg {
    width: 134px;
    height: 92px;
  }

  .text--md {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: $action-color;
    padding: 10px;
    border-radius: $block-radius;
  }

  > img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
  }
}
