@import "../../assets/scss/shared/index";

.progress {
  display: flex;
  align-items: center;
  width: 100%;
}

.progress__bar {
  flex: auto;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  background-color: $correct-color;
}

.progress__bar-indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: $success-color;
}

.progress__label {
  margin-left: 14px;
  min-width: 45px;
  text-align: left;
}
