@import "../../../assets/scss/shared";

.question-audio {
  background: $layout-color;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.08);
  border-radius: $block-radius;
  display: flex;
  align-items: center;
  padding: 11px 30px 11px 10px;
}

.question-audio__player {
  display: flex;
  align-items: center;
}

.question-audio__play {
  display: flex;
  align-items: center;
  cursor: pointer;

  --el-fonts--icon-color: #{$brand-color};
}

.question-audio__play-icon {
  height: 18px;
  width: 18px;
}

.audio-player__progress-bar {
  background-color: $border-light-color;
  border-radius: 10px;
  margin: 0 8px;
  cursor: pointer;

  &::-webkit-slider-runnable-track {
    height: 8px;
    color: $brand-color;
  }

  &::-webkit-slider-thumb {
    width: 0;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: transparent;
    box-shadow: -80px 0 0 80px $brand-color;
  }

  &::-moz-range-progress {
    background-color: $brand-color;
  }

  &::-moz-range-track {
    background-color: $label-color;
  }

  &::-ms-fill-lower {
    background-color: $brand-color;
  }

  &::-ms-fill-upper {
    background-color: #9a905d;
  }
}

.question-audio__controls {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 18px 18px;
  align-items: center;
  padding: 0 10px;
  border-left: 1px solid $border-light-color;
}

.question-audio__control {
  cursor: pointer;
  width: 18px;
  height: 18px;

  &:hover {
    --el-fonts--icon-color: #{$brand-color};
  }
}
