@import "../../assets/scss/shared/index";

[class*=toggle--] {
  label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
}

.toggle--primary {
  input:checked {
    ~ .toggle--primary__switch {
      --el-fonts--label-color: #{$brand-color};

      &:before {
        background-color: $brand-color;
      }
    }
  }

  input:not(:checked) {
    ~ .toggle--primary__switch {
      transform: rotate(-90deg);
    }
  }
}

.toggle--theme {
  input:checked {
    ~ span {
      border-color: inherit;
    }
  }

  input:not(:checked) {
    ~ .toggle--primary__switch {
      transform: rotate(-90deg);
    }
  }
}

.toggle--primary__switch {
  min-width: 18px;
  height: 18px;
  border: 1px solid $label-color;
  border-radius: 4px;
  position: relative;
  transition: $move-ease;
  margin-right: 10px;

  &:before {
    content: '';
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    transition: inherit;
    border-radius: 2px;
  }
}

.toggle--radio {
  input:checked {
    ~ .toggle--radio__switch {
      --el-fonts--label-color: #{$brand-color};

      &:before {
        background-color: $brand-color;
      }
    }
  }

  input:not(:checked) {
    ~ .toggle--radio__switch {
      transform: rotate(-90deg);
    }
  }
}

.toggle--radio__switch {
  min-width: 18px;
  height: 18px;
  border: 1px solid $label-color;
  position: relative;
  border-radius: 50%;
  transition: $move-ease;
  margin-right: calc(#{$source-offset} / 2);

  .error & {
    border-color: $error-color
  }

  &:before {
    content: '';
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    transition: inherit;
    border-radius: inherit;
  }
}

.toggle--check {
  input:checked {
    ~ svg {
      border: 0;
      --el-fonts--icon-color: #{$text-color};
      --el-general--layout-color: #{$brand-color};
    }
  }

  svg {
    min-width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid $label-color;
    transition: fill .3s ease;
    margin-right: 8px;
    background-color: $layout-color;

    --el-fonts--icon-color: transparent;
    --el-general--layout-color: transparent;
  }

  label {
    @include font($text-color 12px 18px);
  }
}

.toggle--success {
  &.error {
    --el-fonts--label-color: #{$error-color}
  }

  input:checked {
    ~ svg {
      border: 0;
      --el-fonts--icon-color: #{$success-color};
      --el-general--layout-color: #{$white-color};
    }
  }

  svg {
    min-width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid $label-color;
    transition: fill .3s ease;
    margin-right: 8px;
    align-self: flex-start;

    --el-fonts--icon-color: transparent;
    --el-general--layout-color: transparent;
  }

  label {
    @include font($text-color 12px 18px);
  }
}

.toggle--off {
  opacity: .5;
  pointer-events: none;
}
