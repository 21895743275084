@import "../../../assets/scss/shared";

.confirm-delete {
  text-align: center;
  width: 300px;
  position: relative;
}

.confirm-delete__info {
  margin: 30px 18px 0;
}

.confirm-delete__info-name {
  margin-bottom: 8px;
  display: flex;
  --el-fonts--icon-color: #{$outline-color};

  svg {
    width: 20px;
    height: 15px;
    margin-right: 10px;
  }
}

.confirm-delete__info-size {
  display: flex;
  margin-bottom: 8px;
  opacity: 0.5;
}

.confirm-delete__info-date {
  margin-bottom: 8px;
  display: flex;
  opacity: 0.5;
}

.confirm-delete__actions {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
