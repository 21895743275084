@mixin fontType($index, $indexTest, $property, $value, $valueImportant: false) {
  @if $index == $indexTest {
    #{$property}: #{$value};
  }
}

@mixin font($arrayValues, $isImportant: false) {
  @for $i from 1 through length($arrayValues) {
    $value: nth($arrayValues, $i);
    @include fontType($i, 1, color, isTrue($isImportant, important($value, $isImportant), $value));
    @include fontType($i, 2, font-size, isTrue($isImportant, important($value, $isImportant), $value));
    @include fontType($i, 3, line-height, isTrue($isImportant, important($value, $isImportant), $value));
    @include fontType($i, 4, font-weight, isTrue($isImportant, important($value, $isImportant), $value));
  }
  @content;
}

@mixin textTruncate($value: 2) {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: $value;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
