@import "../../../assets/scss/shared/index";

.card-status {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  @include xs() {
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: $block-radius;
    background-color: $icon-color;

    + div {
      margin-left: 10px;
    }
  }

  &:hover {
    .card-status__txt {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.card-status__icon {
  width: 18px;
  height: 18px;
  margin: 6px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.card-status__txt {
  position: absolute;
  top: -4px;
  left: -4px;
  width: 154px;
  height: 38px;
  background-color: $layout-color;
  box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.11);
  border-radius: $block-radius;
  opacity: 0;
  padding: 0 15px 0 40px;
  line-height: 38px;
  transition: $move-ease;
  pointer-events: none;
}

.card-status--working {
  --el-fonts--icon-color: #{$success-color};
}

.card-status--stopped {
  --el-fonts--icon-color: #{$error-color};
}
