@import "../../assets/scss/shared/index";

.label-switch {
  display: grid;
  grid-template-columns: calc(100% - 58px) 58px;
  transition: opacity .3s ease;

  &:not(.label-switch--active) {
    opacity: .6;
  }
}

.label-switch__desc {
  margin-top: 6px;
  --el-fonts--text-color: #{$label-color}
}

.label-switch__tumbler {
  display: block;
  grid-row: span 2;
  position: relative;
  width: 58px;
  height: 27px;
  background-color: $border-color;
  border-radius: 37px;
  cursor: pointer;
  transition: $move-ease;

  .label-switch--active & {
    --el-layout--border-color: #{$brand-color}
  }
}

.label-switch__input {
  &:checked {
    ~ .label-switch__target {
      transform: translateX(31px);
    }
  }
}

.label-switch__target {
  position: absolute;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: $layout-color;
  top: 2px;
  left: 2px;
  transition: transform .3s ease;
}
