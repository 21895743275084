@import "../../assets/scss/shared/index";

.prompt {
  position: relative;
  z-index: 0;
}

.prompt--view {
  &:not(.prompt--size) {
    padding-right: 25px;

    .prompt__polygon {
      width: 100%;
    }

    .prompt__icon {
      right: 0;
    }
  }
}

.prompt__polygon {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 45px);
  height: 100%;
  z-index: -10;
}

.prompt__icon {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: all;
  display: flex;
  align-items: center;
  //opacity: 0;
  //animation: fade .3s ease 1s both;
}

.prompt__polygon--md {
  width: calc(100% + 65px);
}

.prompt__polygon--lg {
  width: calc(100% + 85px);//TODO [Test]: Registration on event site
}
