@import "../../assets/scss/shared/index";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

.editor-text {
  display: flex;
  flex-direction: row-reverse;
  position: relative;

  @include less() {
    flex-direction: column-reverse;
    grid-gap: 10px;
  }

  > .text--eject {
    width: #{'min(160px, 100%)'};
    margin-right: auto;
    padding-right: $default-offset;
  }
}

.editor-text__polygon {
  flex: 1 1;
  border-radius: $block-radius;
  max-width: 100%;
  position: relative;
  min-height: 360px;
  background-color: $layout-color;

  .editor-text--error & {
    border-color: $error-color;
  }

  > svg {
    position: absolute;
    top: 10px;
    width: calc(100% - 90px);
    height: calc(100% - 30px);
    left: 10px;
  }

  > div {
    border: 0;
    border-radius: 8px;
  }

  .tox .tox-statusbar__text-container {
    display: none;
  }

  .tox .tox-mbtn {
    color: $label-color
  }

  .tox {
    .tox-statusbar {
      border: 0;
    }

    .tox-toolbar--scrolling {
      height: 40px;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        display: none;
      }

      //&::-webkit-scrollbar-thumb {}
    }

    .tox-toolbar__primary {
      padding: 7px 0;
      background: none;
      border-bottom: 1px solid $outside-color;

      svg {
        fill: $text-color;
      }
    }

    &:not([dir=rtl]) {
      .tox-toolbar__group {
        &:not(:last-of-type) {
          border-color: $outside-color
        }
      }
    }

    .tox-toolbar__group {
      height: 25px;

      @include xs() {
        height: 40px;
      }

      button {
        height: 25px;

        @include xs() {
          height: 40px;
        }
      }
    }
  }
}

.editor-text__polygon--primary {
  box-shadow: 0px 4px 30px 7px rgba(0, 0, 0, 0.05);
  border: 1px solid transparent;
}

.editor-text__polygon--answer {
  border: 1px solid $border-light-color;
}

.editor-text__error {
  position: absolute;
  top: 100%;
  left: 16px;

  --el-fonts--text-color: #{$error-color};
}

.mce-notification, .tox .tox-notification--warn, .tox .tox-notification--warning {
  display: none !important;
}

.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  color: $label-color
}

.editor-text__width {
  position: fixed;
  max-width: 970px;
  opacity: 0;

  ul,
  ol {
    margin-left: 40px;
  }
}

.editor-text__wrapper {
  display: grid;
  width: #{'min(720px, 100%)'};
}

.editor-text--preview {
  font-family: Montserrat, sans-serif;
  font-weight: 500;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $text-color;
    font-weight: 700;
  }

  h1 {
    font-size: 26px;
    line-height: 32.5px;
    margin: 17.42px 0;
  }

  h2 {
    font-size: 16px;
    margin: 13.28px 0;
  }

  h3 {
    font-size: 14px;
    margin: 14px 0;
  }

  h4, h5, h6 {
    font-size: 12px;
    margin: 15.96px 0;
  }

  b, strong, em {
    font-weight: 700;
  }

  p {
    margin: 15px 0;
    font-size: 14px;
  }

  ul + ul, ul + ol, ol + ol, ol + ul {
    padding-top: 10px;
  }

  ul,
  ol {
    padding: 0;
    margin: 10px 0;
    padding-inline-start: 20px;
  }

  ul li:not(:last-child), ol li:not(:last-child) {
    margin-bottom: 10px;
  }

  ul > li {
    list-style: disc;
  }

  ul > li ul li {
    list-style: circle;
  }

  ol > li ol li {
    list-style: lower-latin;
  }

  ol > li {
    list-style: auto;
  }
}
