@import "../../../assets/scss/shared/index";

.modal-question--video {
  width: 620px;
  max-width: 100%;
}

.modal-question__title {
  margin-bottom: 20px;
}

.modal-question__desc {
  margin-bottom: 20px;
}

.modal-question__panel {
  margin-top: 20px;
  display: grid;
  max-width: calc(100% - 160px);
  margin-left: auto;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 20px;

  @include xs() {
    max-width: 100%;
  }
}

.modal-question__attached {
  border: 1px solid #C6CFE1;
  border-radius: $block-radius;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 220px;
  padding: 9px 15px;
  cursor: pointer;
  transition: $move-ease;

  &:hover {
    background-color: $brand-color;
    border-color: $brand-color;
  }
}

.modal-question__attached-icon {
  margin-right: 10px;
}

.modal-question__buttons {
  display: grid;
  grid-gap: 20px;

  @include xs(min) {
    grid-template-columns: repeat(auto-fit, 220px 220px);
  }
}
