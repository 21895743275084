@import "../../assets/scss/shared/index";

.content-menu-2 {
  background-color: $layout-color;
  border-radius: $block-radius;
  box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  padding: 12.5px 0;
  //animation: slideUp .3s ease, fade .3s ease
}

.content-menu-2__option {
  display: flex;
  align-items: center;
  padding: 7.5px 15px;
  cursor: pointer;
}

.content-menu-2__option-label {
  font-size: 16px;
  line-height: 18px;
}

.content-menu-2__option-switch {
  font-size: 0;
  display: flex;
}

.content-menu-2--scroll {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.content-menu-2__scroll {
  flex: auto;
  overflow: hidden;
  margin: 0 -20px 0 -15px;
}

.content-menu-2__label {
  margin-bottom: 15px;
  --el-fonts--text-color: #{$label-color};
}

.content-menu-2__head {
  margin-bottom: 20px;
}
