@import "../../assets/scss/shared/index";

.pagination {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
}

.pagination__controls, .pagination__perp {
  display: flex;
  align-items: center;
  border-radius: $block-radius;
  background-color: $element-color;
  padding: 11.5px 21px;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.05);
  grid-gap: 20px;

  @include xs() {
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 15px;
  }
}

.pagination__controls {
  background-color: $element-color;

  .rc-pagination-disabled {
    --el-fonts--icon-color: #{$dashed-color};
    pointer-events: none;
  }
}

.pagination__slider {
  display: flex;
  align-items: center;
}

.rc-pagination {
  display: flex;
  align-items: center;
}

.pagination__input {
  display: flex;
  align-items: center;
  margin-left: 39px;

  .input--inline {
    input {
      width: 44px;
      height: 32px;
      background-color: $layout-color;
      box-shadow: 0px 2px 30px rgba(0, 0, 0, .05);
      text-align: center;
    }
  }
}

.pagination__input-label {
  --el-fonts--text-color: #{$icon-dark-color};
}

.pagination__slide, .rc-pagination-item, .rc-pagination-jump-next, .rc-pagination-jump-prev {
  cursor: pointer;

  &:not(:first-child):not(:last-child) {
    margin: 0 3px;
    padding: 0 5px;
  }

  &:hover, &.rc-pagination-item-active {
    --el-fonts--text-color: #{$outline-color};
  }

  &.rc-pagination-item-active .pagination__slide {
    font-weight: 700;
  }
}

.pagination__slide {
  @include xs() {
    --el-ui-text-md: 16px;
    --el-ui-text-line-md: 1;
  }
}

.pagination__input-btn {
  cursor: pointer;
  transform: rotate(180deg);
}

.pagination__slider-next, .pagination__slider-prev, .pagination__input-btn {
  cursor: pointer;

  &:hover {
    --el-fonts--icon-color: #{$text-color};
  }
}

.pagination__slider-prev {
  margin-right: 8px;
}

.pagination__input-field {
  margin: 0 $source-offset;
}

.pagination__slider-next {
  margin-left: 8px;
  transform: rotate(180deg);
}

.pagination__input-select {
  width: 80px;

  .select--primary__selected {
    line-height: 32px;
    height: 32px;
    font-size: 14px;

    svg {
      top: 8px;
    }
  }
}

.pagination__perp-label {
  --el-fonts--text-color: #{$label-color};
}
