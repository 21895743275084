@import "../../assets/scss/shared/index";

.content-menu {
  background-color: $layout-color;
  border-radius: $block-radius;
  box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.content-menu__item {
  height: 41px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;

  &:hover {
    background-color: $outside-color;

    --el-fonts--text-color: #{$outline-color};
  }
}
