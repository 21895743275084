@import "../../../../../assets/scss/shared";

.answer-choice {
  position: relative;
  padding: 10px 30px;
  //width: calc(100% + 40px);
  &:not(:hover) {
    i {
      opacity: 0;
    }
  }
}

.answer-choice__entry {
  display: flex;
  align-items: flex-start;

  --el-fonts--icon-color: #{$brand-color};

  @include xs() {
    flex-wrap: wrap;
    grid-gap: 10px;
    justify-content: center;
  }

  > [class*=toggle--] {
    margin-top: 13px;
  }

  > .handle--question {
    top: 24px;
    right: calc(100% - 15px);

    --el-fonts--icon-color: #{$label-color};
  }

  .square--eject {
    &:hover, &.comment--active {
      g {
        opacity: 1;
      }
    }
  }

  .textarea--answer {
    flex: auto;
    margin-right: 15px;

    @include xs() {
      width: calc(100% - 140px);
      margin-right: 0;
    }
  }

  .scores--primary {
    margin-right: 15px;

    @include xs() {
      flex: auto;
      margin-left: 36px;
      margin-right: 0px;
    }
  }
}

.answer-choice__entry-trash {
  cursor: pointer;
  position: absolute;
  left: calc(100% - 20px);
  top: 24px;
  width: 16px;
  height: 16px;

  --el-fonts--icon-color: #{$monochrome-color};

  svg {
    width: 12px;
    height: 14px;
  }
}

.answer-choice__comment {
  max-width: 908px;
  margin: 19px auto 0 0;

  @include sm(min) {
    padding: 0 28px;
  }
}

.answer-choice__comment--active {
  --el-fonts--label-color: #{$text-color}
}
