@import "../../assets/scss/shared/index";

.file-field {
  display: flex;
  position: relative;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.file-field__label {
  width: #{'min(160px, 100%)'};
  padding-right: 15px;
}

.file-field__polygon {
  flex: auto;
  width: calc(100% - 160px);
}

.file-field__button {
  position: relative;
  grid-gap: 12px;
}

.file-field__input {
  display: none;
}

.file-field__attach {
  position: relative;
  margin-bottom: 10px;
}

.file-field__attach-file {
  padding: 11px 15px;
  width: 100%;
  justify-content: flex-start;

  + .file-field__attach-file {
    margin-top: 5px;
  }
}

.file-field__attach-name {
  @include textTruncate(1);
}

.file-field__attach-trash {
  position: absolute;
  top: 10px;
  left: calc(100% + 13px);
  cursor: pointer;

  &:hover {
    --el-fonts--icon-color: #{$outline-color};
  }
}

.file-field__preview {
  margin-bottom: 10px;
}

.file-field__image {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: $block-radius;
}
