// Variables:
//
// Should type for: | `$element-state-property`
//                  | `$state-property`
//                  | `$property`

//Default colors:

$primary-color: #64008d;
$success-color: #1b7c46;
$danger-color: #ff0000;
$warning-color: #ed8e00;
$test-badge-color: #E6F3B5;
$homework-badge-color: #EFE4FF;
$materials-badge-color: #CBD1F3;
$page-badge-color: #FEEBBD;

$colorBlue: #025fa5;
$colorEdbee: #FDC63B;
$colorPink: #f91880;
$colorPurple: #7955ff;
$colorOrange: #ff7900;
$colorGreen: #00ba7c;

$dark: #989898;
$grеy: #3c3c3c;

//Custom properties colors:

$layout-color: var(--el-general--layout-color);
$bg-color: var(--el-general--bg-color);
$line-color: var(--el-general--line-color);
$outline-color: var(--el-layout--outline-color);
$dashed-color: var(--el-layout--dashed-color);
$build-color: var(--el-layout--build-color);
$border-color: var(--el-layout--border-color);
$border-light-color: var(--el-layout--border-light-color);
$border-force-color: var(--el-layout--border-force-color);
$border-dark-color: var(--el-layout--border-dark-color);
$border-easy-color: var(--el-layout--border-easy-color);
$event-border-color: var(--el-layout--event-border-color);
$outside-color: var(--el-layout--outside-color);
$monochrome-color: var(--el-accent--monochrome-color);
$name-color: var(--el-accent--name-color);
$events-color: var(--el-layout--events-color);
$error-color: var(--el-general--error-color);
$part-color: var(--el-layout--part-color);
$event-section-bg-color: var(--el-layout--events-section-bg-color);
$content-card-bg-color: var(--el-layout--content-card-bg-color);
$bg-dark-color: var(--el-general--bg-dark-color);
$element-color: var(--el-general--element-color);
$input-color: var(--el-ui--input-color);
$ui-block-color: var(--el-ui--block-color);
$correct-color: var(--el-accent--correct-color);
$no-correct-color: var(--el-accent--no-correct-color);
$accept-color: var(--el-accent--accept-color);
$accept-color-2: var(--el-accent--accept-color-2);
$mark-color: var(--el-general--mark-color);

$element-file-color: var(--el-ui--element-file-color);
$element-homework-color: var(--el-ui--element-homework-color);
$element-test-color: var(--el-ui--element-test-color);
$element-page-color: var(--el-ui--element-page-color);
$element-image-color: var(--el-ui--element-image-color);
$element-stream-color: var(--el-ui--element-stream-color);
$element-video-color: var(--el-ui--element-video-color);
$element-webinar-color: var(--el-ui--element-webinar-color);
//
$brand-color: var(--el-general--brand-color);
$accent-color: var(--el-general--accent-color);
$action-color: var(--el-general--action-color);
$white-color: var(--el-general--white-color);
$black-color: var(--el-general--black-color);

//font colors:

$text-color: var(--el-fonts--text-color);
$text-dark-color: var(--el-fonts--text-dark-color);
$label-color: var(--el-fonts--label-color);
$link-color: var(--el-fonts--link-color);
$title-color: var(--el-fonts--title-color);
$icon-color: var(--el-fonts--icon-color);
$icon-fill-color: var(--el-fonts--icon-fill-color);
$icon-dark-color: var(--el-fonts--icon-dark-color);
$sheet-color: var(--el-fonts--sheet-color);
$success-color: var(--el-accent--success-color);

//ui colors:

$input-bg-color: var(--el-ui--input-bg-color);
$button-color: var(--el-ui--button-color);
$button-text-color: var(--el-ui--button-text-color);
$button-hover-color: var(--el-ui--button-hover-color);
$button-active-color: var(--el-ui--button-active-color);
$pagination-progress-color: var(--el-ui--pagination-progress-color);
$target-color: var(--el-ui--target-color);
$t-panel-head-pg: var(--el-t-panel-head-pg);
$app-body-aside-width: var(--el-app-body-aside-width);
$ui-field-mg: var(--el-ui-field-mg);
$learn-number-color: var(--el-ui--learn-number-color);
$learn-number-shadow-color: var(--el-ui--learn-number-shadow-color);
$t-source-grid-width: var(--el-t-source-grid-width);
$t-panel-head-height: var(--el-t-panel-head-height);
$t-aside-head-mg: var(--el-t-aside-head-mg);
$logo-type: var(--el-logo-type);
$logo-animation-text: var(--el-logo-animation-text);
$logo-overflow: var(--el-logo-overflow);
$tabs-primary-link-offset: var(--el-tabs-primary-link-offset);
$source-offset: var(--el-source-offset);
$block-color: var(--el-layout--block-color);
$aside-color: var(--el-layout--aside-color);
//Media:

$ui-text-lg: var(--el-ui-text-lg);
$ui-text-line-lg: var(--el-ui-text-line-lg);
$ui-text-md: var(--el-ui-text-md);
$ui-text-line-md: var(--el-ui-text-line-md);
$ui-text-sm: var(--el-ui-text-sm);
$ui-text-line-sm: var(--el-ui-text-line-sm);
$ui-text-xs: var(--el-ui-text-xs);
$ui-text-line-xs: var(--el-ui-text-line-xs);

$move-ease: all .3s ease;
$default-offset: 15px;
$block-radius: 6px;
$element-radius: 4px;
$shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
$family: 'Montserrat', sans-serif;
