@import "../../assets/scss/shared/index";

.ability-settings {
  display: flex;

  > i {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    --el-fonts--icon-color: #{$icon-dark-color};
  }

  svg {
    height: 20px;
  }

  > div {
    animation: fade .3s ease;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
