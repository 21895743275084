@import "../../assets/scss/shared/index";

[class*=text--] {
  color: $text-color;
  font-weight: 500;
}

.text--lg {
  font-size: $ui-text-lg;
  line-height: $ui-text-line-lg;
}

.text--md {
  font-size: $ui-text-md;
  line-height: $ui-text-line-md;
}

.text--sm {
  font-size: $ui-text-sm;
  line-height: $ui-text-line-sm;
}

.text--xs {
  font-size: $ui-text-xs;
  line-height: $ui-text-line-xs;
}

.text--b {
  font-weight: 600;
}

.text--underline {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    --el-fonts--text-color: #{$outline-color}
  }
}

.text--hm-primary {
  cursor: pointer;
  display: inline-block;

  &:hover {
    --el-fonts--text-color: #{$outline-color};
    text-decoration: underline;
  }
}

.text--disabled {
  pointer-events: none;
}
