@import "../../assets/scss/shared/index";

[class*=select--] {
  display: flex;
  position: relative;
  text-align: left;
}

.select--error {
  .select--primary__selected {
    outline: 1px solid $error-color;
  }
}

.select--primary {
  flex-direction: row-reverse;
  align-items: center;
  flex-wrap: wrap;

  @include less() {
    flex-direction: column-reverse;
    grid-gap: 10px;
  }

  label {
    @include font($text-color 16px 24px 500);
    width: #{'min(160px, 100%)'};
    margin-right: auto;
    word-spacing: 2px;
    padding-right: 10px;
  }

  &:not(.select--auto) {
    .select--primary__selected {
      min-width: 220px;
    }
  }
}

.select--primary__selected {
  @include font($title-color 16px 46px 500);
  box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05);
  height: 44px;
  border-radius: $block-radius;
  position: relative;
  flex: 1 1 #{'min(290px, calc(100% - 160px))'};
  @include textTruncate(1);

  @include less() {
    width: 100%;
  }

  &.active {
    &:not(.move) {
      border-radius: $block-radius $block-radius 0 0;
    }

    &.move {
      border-radius: 0 0 $block-radius $block-radius;
    }

    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    position: absolute;
    top: 13px;
    right: 12px;
    pointer-events: none;
    --el-fonts--icon-color: #{$text-color};
  }
}

.select--primary__selected, .select--primary__option {
  background-color: $input-bg-color;
  padding: 0 $default-offset;
  cursor: pointer;
}

.select--primary__selection {
  position: fixed;
  z-index: 11; //3
  overflow: hidden;
  animation: fade .3s;
  background-color: $white-color;

  &:not(.move) {
    border-radius: 0 0 $block-radius $block-radius;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);

  }

  &.move {
    border-radius: $block-radius $block-radius 0 0;
    box-shadow: 0 -4px 30px rgba(0, 0, 0, 0.05);
  }
}

.select--primary__option {
  @include font($text-color 16px 44px 500);
  display: flex;
  align-items: center;
  position: relative;

  &:hover, &.active {
    background-color: $outside-color;
    --el-fonts--text-color: #{$outline-color};
  }

  .toggle--primary {
    margin-left: auto;
    padding-left: $default-offset;
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.select--secondary {
  display: flex;
  flex-direction: column-reverse;

  label {
    @include font($label-color 12px 24px 500);
    width: 100%;
    margin-bottom: 4px;
  }
}

.select--secondary__selected {
  @include font($text-color 14px 38px 500);
  background-color: $outside-color;
  border-radius: $block-radius;
  padding: 0 15px;
  cursor: pointer;

  i {
    margin-left: auto;
  }
}

.select--secondary__selection {
  position: fixed;
  z-index: 3;
  overflow: hidden;
  animation: fade .3s;
  padding: 6px 0 4px;
  background-color: $white-color;
}
