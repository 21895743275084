@import "../../assets/scss/shared/index";

.label-desc__desc {
  margin-top: 6px;
  --el-fonts--text-color: #{$label-color}
}

.label-desc__description {
  margin-top: 15px;
}

.label-desc__icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  &:hover {
    --el-fonts--icon-color: #{$success-color}
  }
}
