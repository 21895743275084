@import "../../assets/scss/shared/index";

[class*=textarea--] {
  &.textarea--error {
    textarea {
      border-color: $error-color;
    }
  }

  &.textarea--b {
    textarea {
      font-weight: 700;
    }
  }

  textarea {
    overflow: hidden;

    &:focus, &:valid {
      ~ .textarea__node {
        display: none;
      }
    }
  }
}

.textarea--primary {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  text-align: left;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  textarea {
    @include font($title-color 16px 24px 500);
    min-height: 90px;
    padding: 15px;
    background-color: $input-bg-color;
    border: 1px solid transparent;
    outline: none;
    border-radius: $block-radius;
    flex: 1 1 #{'min(290px, calc(100% - 160px))'};
    box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05);
    @include placeholder(color, $label-color);
    @include placeholder(opacity, .7);
  }

  .textarea__node {
    top: 10px;
    left: 15px;
    font-size: 16px;
    line-height: 24px;
    color: $label-color;
    font-weight: 500;
    opacity: .7; //

    @include less() {
      top: 40px;
    }
  }

  label {
    @include font($text-color 16px 21px 500);
    width: #{'min(160px, 100%)'};
    margin-right: auto;

    + .textarea__node {
      left: 175px;

      @include less() {
        left: 15px;
      }
    }
  }

  span {
    @include font($error-color 14px 21px);
    position: absolute;
    right: 0;
    bottom: 100%;
    transform: translateY(50%);
    background-color: $white-color;
    padding-left: 10px;
  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }

  @include less() {
    flex-direction: column-reverse;
    grid-gap: 10px;

    textarea {
      flex: auto;
      width: 100%;
    }
  }
}

.textarea--question {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  text-align: left;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  textarea {
    @include font($title-color 16px 24px 500);
    min-height: 360px;
    max-height: 720px;
    padding: 15px 27px;
    background-color: $input-bg-color;
    border: 0;
    outline: none;
    flex: 1 1 #{'min(290px, calc(100% - 160px))'};
    box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05);
    @include placeholder(color, $label-color);

    @include placeholder(opacity, .7);
  }
}

.textarea--choice {
  display: inline-flex;
  flex-direction: row-reverse;
  position: relative;
  text-align: left;
  align-items: center;
  flex-wrap: wrap;

  textarea {
    @include font($title-color 16px 24px 500);
    min-height: 44px;
    max-height: 720px;
    padding: 12px 15px;
    background-color: $input-bg-color;
    border: 0;
    //min-width: 700px;
    outline: none;
    flex: 1 1 #{'min(700px, calc(100% - 160px))'};
    box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05);
    @include placeholder(color, $label-color);

    @include placeholder(opacity, .7);
  }
}

.textarea--answer {
  position: relative;

  textarea {
    @include font($title-color 16px 24px 500);
    min-height: 42px;
    height: 42px;
    max-height: 700px;
    padding: 9px 15px;
    background-color: $input-bg-color;
    border-radius: $block-radius;
    min-width: 100%;
    max-width: 100%;
    resize: none;
    width: 100%;
    overflow: hidden;
    outline: none;
    flex: 1 1 #{'min(700px, calc(100% - 160px))'};
    box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05);
    border: 1px solid transparent;
    @include placeholder(color, $label-color);
    @include placeholder(opacity, .4);

    &:focus, &:valid {
      ~ .textarea__node {
        display: none;
      }
    }
  }

  .textarea__node {
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    font-size: 16px;
    line-height: 24px;
    color: $label-color;
    font-weight: 500;
    opacity: .7;
  }
}

.textarea--comments {
  position: relative;

  textarea {
    @include font($title-color 16px 24px 500);
    min-height: 42px;
    height: 42px;
    max-height: 700px;
    padding: 9px 15px;
    background-color: $input-bg-color;
    border-radius: $block-radius;
    min-width: 100%;
    max-width: 100%;
    resize: none;
    width: 100%;
    overflow: hidden;
    outline: none;
    flex: 1 1 #{'min(700px, calc(100% - 160px))'};
    box-shadow: none;
    border: 1px solid $border-light-color;
    @include placeholder(color, $icon-color);
  }

  .textarea__node {
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    font-size: 16px;
    line-height: 24px;
    color: $label-color;
    font-weight: 500;
    opacity: .7;
  }
}

.textarea--error-text {
  position: absolute;
  top: 100%;
  left: 15px;
  --el-fonts--text-color: #{$error-color};
}

.textarea--comment {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  text-align: left;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid $icon-color;
  border-radius: $block-radius;

  textarea {
    @include font($label-color 16px 19.5px 500);
    height: 44px;
    padding: 12px;
    border: 0;
    background-color: transparent;
    outline: none;
    width: 100%;
    min-width: 100%;
    resize: none;

    @include placeholder(opacity, .4);
    @include placeholder(color, $label-color);
  }

  .textarea__node {
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    font-size: 16px;
    line-height: 24px;
    color: $label-color;
    font-weight: 500;
    opacity: .7;

    @include less() {
      top: auto;
      transform: none;
      bottom: 1px;
      line-height: 21px;
    }
  }
}

.textarea--md {
  textarea {
    font-size: 14px;
    line-height: 21px;
  }
}

.textarea--focusin {
  position: relative;

  textarea {
    width: 100%;
    min-width: 100%;
    border: 0;
    height: 51px;
    resize: none;
    outline: none;
    border-radius: $block-radius;
    color: $title-color;
    font-weight: 500;
    padding: 15px 27px;
    @include placeholder(color, $label-color);
    @include placeholder(opacity, .5);
    box-shadow: 0px 4px 30px 7px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    background-color: $element-color;

    &:not(:focus):not(:hover) {
      box-shadow: none;
    }
  }

  .textarea__node {
    font-size: 26px;
    line-height: 24px;
    color: $label-color;
    font-weight: 700;
    opacity: .7;
    top: 16px;
    left: 27px;
  }
}

.textarea__node {
  position: absolute;
  pointer-events: none;

  @include less() {
    bottom: 10px;
  }
}
