:root {
  @include map($root, 'desktop');
}

@include lg() {
  :root {
    @include map($root, 'lg');
  }
}

@include md() {
  :root {
    @include map($root, 'md');
  }
}

@include sm() {
  :root {
    @include map($root, 'sm');
  }
}

@include xs() {
  :root {
    @include map($root, 'xs');
  }
}

html:not([data-layout]), html[data-layout*=light] {
  @include map($themes, 'light');
}

html[data-layout*=dark] {
  background-color: #15202b;
  @include map($themes, 'dark');
}

html:not([data-coloring]), html[data-coloring*=Edbee] {
  @include map($themesColor, 'colorEdbee');
}

html[data-coloring*=Blue] {
  @include map($themesColor, 'colorBlue');
}

html[data-coloring*=Purple] {
  @include map($themesColor, 'colorPurple');
}

html[data-coloring*=Pink] {
  @include map($themesColor, 'colorPink');
}

html[data-coloring*=Orange] {
  @include map($themesColor, 'colorOrange');
}

html[data-coloring*=Green] {
  @include map($themesColor, 'colorGreen');
}

@include reset();

@include declareAnimate($animations);

//@include debug();

//@include layer2();
