@import "../../../../assets/scss/shared";

.scores--primary {
  position: relative;
  padding: 9px 15px;
  border-radius: 6px;
  background-color: $input-bg-color;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 30px 7px rgba(0, 0, 0, 0.05);
  border: 1px solid transparent;

  &:not(.scores--active) {
    --el-fonts--title-color: #{$label-color};
    --el-general--accent-color: #{$label-color};
  }

  input {
    @include font($title-color 16px 24px 500);
    height: 24px;
    outline: none;
    border: 0;
    width: 43px;
    border-bottom: 1px solid $accent-color;
    text-align: center;
    margin-left: 20px;
    background-color: $input-bg-color;
  }
}

.scores--error {
  border-color: $error-color
}

.scores--error-text {
  position: absolute;
  top: 100%;
  left: 15px;
  --el-fonts--text-color: #{$error-color};
}
