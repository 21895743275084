@import "../../assets/scss/shared/index";

.app {
  display: flex;
  flex-direction: column;
  animation: fade .3s;
  background-color: $bg-color;

  @include sm(min) {
    height: 100vh;
  }
  
  @include sm() {
    min-height: 60vh; //for box-shadow in elements -> overflow
  }

  &:not(.app--host) {
    user-select: none;
  }

  &.dark {
    --el-general--bg-color: #{$build-color}
  }
}

.app--dashboard {
  background-color: $bg-dark-color;
  --el-t-panel-head-pg: 16px 20px 11px
}

.app__head {
  .t--panel__head {
    padding-left: 60px;
  }
}

.app__body {
  display: flex;
  //height: 100%;
  flex: auto;

  @include sm(min) {
    overflow: hidden;
  }

  @include sm() {
    flex-direction: column;
  }
}

.app__aside:not(.app__aside--mobile) {
  width: $app-body-aside-width;
  background-color: $layout-color;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: #{'max(#{$default-offset}, 19.2px)'} $default-offset - 1;
  transition: width .3s ease, min-width .3s ease;
}

.app__aside--mobile {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $bg-color;
  z-index: 1;
}

.app__aside-head {
  margin: $t-aside-head-mg;
  max-width: 174px;
  width: 100%;

  svg {
    height: 39px;
  }
}

.app__aside-body {
  flex: auto;
  overflow: hidden;
  margin: 0 -15px;

  > .button--primary {
    margin-bottom: $source-offset;
  }
}

.app__aside-inner {
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
}

.app__body-every {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  > div {
    position: fixed;
  }
}

.app__body-scroller {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  background-color: transparent;
  cursor: col-resize;
  z-index: 1;
  pointer-events: none;

  .app__aside--compact & {
    svg {
      transform: rotate(180deg);
      margin-left: 1px;
    }
  }

  span {
    pointer-events: all;
    right: -8px;
    position: absolute;
    top: 20px;
    z-index: 1;
    left: calc(100% - 12px);
    justify-content: center;
    background-color: $layout-color;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-shadow: 6px 0 5px rgba(0, 0, 0, .05);

    --el-fonts--icon-color: #{$button-active-color};

    svg {
      width: 8px;
      height: 10px;
    }
  }
}

.app__body-main {
  flex: 1 1 auto;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: margin .3s ease;

  &.overlay {
    filter: blur(5px);
  }
}

.app__body-content {
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  > div {
    animation: fade .3s;
  }
}

.app__aside--active {
  .logo {
    --el-logo-animation-text: fadeIn .3s ease both;
  }
}

.app__aside--compact {
  min-width: 80px !important;
  width: 80px !important;
  --el-t-aside-head-mg: 0 0 40px auto;
  --el-logo-overflow: hidden;

  //
  .logo__type {
    transform: translatex(-27px);
  }
}

.app__ew {
  display: flex;
  align-items: center;
  margin: auto;
}

.app__ew-btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
}

//TODO [Rework]: page site on works
.app__ew-icon {
  width: 50px;
  margin-right: 20px;
  height: 50px;
}

//TODO [Rework]: page site on works
.app__container {
  overflow: initial;
  width: #{'min(100%,1220px)'};
  margin: 0 auto; // 100px;
  padding: 0 var(--el-source-offset);
}

.app__container--bottom {
  margin-bottom: 100px;
}

.app__route {
  flex: auto;
  overflow: hidden;
}

.app__mobile-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $layout-color;
  z-index: 2;
  padding: 20px 0;
}
