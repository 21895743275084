@import "../../assets/scss/shared/index";

.attach--eject {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}

.attach__data {
  display: flex;
  align-items: center;
  flex: auto;

  //@include xs(){
  //  position: relative;
  //  z-index: 1;
  //}
}

.attach__desc {
  margin-left: 14px;

  .text--eject {
    @include textTruncate(1);
  }

  .text--md {
    --el-fonts--text-color: #{$label-color}
  }

  @include xs() {
    max-width: 70%;
  }
}

.attach__addition {
  margin-left: auto;
  text-align: right;
  min-width: 320px;

  svg {
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }

  .button--eject {
    margin-left: $source-offset;
  }
}

.attach__content {
  width: 100%;
  height: 596px;
  background-color: $events-color;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  border-radius: $block-radius;
  overflow: hidden;

  img, div {
    width: 100%;
    height: 100%;
  }
}

.attach__fields {
  width: 100%;
  margin-top: $source-offset;

  --el-fonts--icon-color: #{$brand-color};
}

.attach__field-more {
  margin-left: 10px;
  cursor: pointer;

  --el-fonts--icon-color: #{$outline-color};
}

.attach__date {
  position: absolute;
  top: 12px;
  right: 48px;
  background: $events-color;
  border-radius: 6px;
  padding: 6px 14px;

  @include xs() {
    top: 5px;
  }
}

.attach__data-icon {
  max-width: 44px;
  max-height: 44px;
  position: relative;
  --el-fonts--icon-color: #{$outline-color};
}

.attach__data-img, .attach__data-img--scale {
  position: absolute;
  object-fit: cover;
  object-position: center;
  border-radius: $block-radius;
}

.attach__data-img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.attach__data-img--scale {
  top: 50%;
  left: calc(100% + 13px);
  transform: translate(0%, -50%);
  width: 210px;
  height: 120px;
  max-width: 300px;
  max-height: 150px;
  box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05);
  animation: fade .3s ease;
}

.attach__data-icon--task {
  --el-layout--outline-color: #{$brand-color};
}
