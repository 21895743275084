@import "../../../../../assets/scss/shared";

.answer-free {
  position: relative;
}

.answer-free__entry {
  display: flex;
  align-items: flex-start;
  --el-fonts--icon-color: #{$brand-color};

  @include xs() {
    flex-wrap: wrap;
    grid-gap: 20px;
  }

  .textarea--answer {
    flex: auto;

    @include xs(min) {
      margin-right: 15px;
    }
  }

  .square--eject {
    &:hover, &.comment--active {
      g {
        opacity: 1;
      }
    }
  }

  .scores--primary {
    @include xs(min) {
      margin-right: 15px;
    }

    @include xs() {
      flex: auto;
    }
  }
}

.answer-free__comment {
  max-width: 883px;
  margin: 19px auto 0 0;
}

.answer-free__comment--active {
  --el-fonts--label-color: #{$text-color}
}
