@import "../../assets/scss/shared/index";

.status {
  padding: 0 5px;
  border-radius: $block-radius;
  text-align: center;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 12px;
  border: 1px solid;

  &:not(.status--bordered) {
    border-color: transparent
  }
}

.status--user {
  border-radius: 10px;
}

.status--accepted {
  background-color: $correct-color;
  --el-fonts--text-color: #{$success-color};
  --el-fonts--icon-color: #{$success-color};
}

.status--pending {
  background-color: #E6E9FC;
  --el-fonts--text-color: #{$outline-color};
  --el-fonts--icon-color: #{$outline-color};
}

.status--rejected {
  background-color: $no-correct-color;
  --el-fonts--text-color: #{$error-color};
  --el-fonts--icon-color: #{$error-color};
}

.status--md {
  width: 140px;
}

.status--lg {
  width: 180px;
}

.status--lgx {
  width: 220px;
  line-height: 42px;
}
