@import "../../assets/scss/shared/index";

.search {
  position: relative;
}

.search--w-md {
  width: #{'min(100%, 420px)'};
}

.search__input {
  &:not(.search--without-icon) {
    input {
      padding-left: 46px;
    }

    .input__node {
      left: 44px;
    }
  }
}

.search__icon {
  position: absolute;
  left: 15px;
  z-index: 1;
  cursor: pointer;

  --el-fonts--icon-color: #{$label-color};

  &:hover {
    --el-fonts--label-color: #{$outline-color};
  }
}

.search--lg {
  .search__icon {
    top: 19px;
    width: 18px;
    height: 18px;
  }
}

.search--md {
  .search__icon {
    top: 13px;
    width: 18px;
    height: 18px;
  }
}

.search--sm {
  .search__icon {
    top: 9px;
    width: 22px;
    height: 22px;
    opacity: .5;
  }
}

.search__square-hider {
  width: 44px;
  height: 44px;
  overflow: hidden;
  border-radius: $block-radius;
  position: relative;
  transition: width .3s ease;
  box-sizing: content-box;
  cursor: pointer;
}

.search__square-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  input {
    padding-left: 45px;
  }

  .input__node {
    left: 45px;
    white-space: nowrap;
  }
}

.search__square-hider--active {
  width: 300px;
}

.search__square-icon {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  padding: 13px;
  box-sizing: content-box;
}

.search__clear {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 44px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    --el-fonts--icon-color: #{$outline-color};
  }
}

.search__clear-icon {
  border-left: 1px solid $label-color;
  padding: 5px 12px;
  box-sizing: content-box;
  width: 15px;
  height: 15px;
}
