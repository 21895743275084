@import "../../assets/scss/shared/index";

.editor-page, .editor-page-parse {
  .ce-block__content {
    max-width: 100%;
  }
}

.editor-page-parse {
  padding: 15px;

  .codex-editor__redactor {
    padding: 0 !important;

    @include xs() {
      margin: 0;
    }
  }
}

.homework-task__page {
  background-color: $layout-color;
  border-radius: $block-radius;
  box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05);
}

.editor-page {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  text-align: left;
  align-items: flex-start;
  flex-wrap: wrap;
  font-family: $family;

  @include less() {
    flex-direction: column-reverse;
    grid-gap: 10px;
  }

  .ce-paragraph[data-placeholder] {
    &:empty::before {
      color: $label-color;
      font-size: 16px;
      font-weight: 500;
      font-family: $family;
    }
  }

  .codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder] {
    &:empty::before {
      opacity: .7;
    }
  }

  .codex-editor--narrow .codex-editor__redactor {
    margin: 0 -20px;
  }
}

.editor-page__polygon {
  flex: 1 1 #{'min(290px, calc(100% - 160px))'};
  background: $layout-color;
  box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 5px 35px;
  border: 1px solid transparent;

  .editor-page--error & {
    border-color: $error-color
  }
}

.editor-page__label {
  width: #{'min(160px, 100%)'};
  margin-right: auto;
  padding-right: $default-offset;
}
