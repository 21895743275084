@import "../../assets/scss/shared/index";

.marks {
  display: grid;
  grid-gap: 20px;
  padding: 20px 0;
}

.marks__button {
  justify-content: flex-start;
  margin-right: auto;

  &:not(:hover) {
    --el-general--brand-color: #{$text-color}
  }

  &:hover {
    --el-fonts--icon-color: #{$brand-color};
  }
}

.marks__button-icon {
  width: 21px;
  height: 21px;
  margin-right: 11px;
}

.mark {
  background-color: $mark-color;
  border-radius: $block-radius;
  padding: 20px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, 220px calc(100% - 304px) 44px);
  grid-gap: 20px;
  width: calc(100% - 20px);
}

.mark--viewer {
  margin-top: 20px;
}

.mark__head {
  position: absolute;
  bottom: calc(100% + 7px);
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 220px calc(100% - 240px));
  grid-gap: 20px;
}

.mark__head-pint {
  --el-fonts--text-color: #{$label-color};
}

.mark__points {
  display: grid;
  grid-template-columns: repeat(auto-fit, 60px 60px 72px);
  grid-gap: 6px;
  --el-fonts--text-color: #{$label-color};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background-color: $layout-color;
  align-items: center;
  padding: 0 15px;
}

.mark__description {
  grid-column: span 3;
}

.mark__trash {
  position: absolute;
  top: 20px;
  left: calc(100% + 20px);
  cursor: pointer;

  &:hover {
    --el-fonts--icon-color: #{$outline-color};
  }
}

.mark__points-input {
  border-bottom: 1px solid $label-color;
  margin: auto;

  input {
    text-align: center;
  }
}

.mark__points-input--empty {
  border-color: $error-color;
  --el-ui--input-color: #{$label-color}
}

.mark__points__label, .mark__points__sub {
  text-align: center;
}

.mark__description-label {
  margin-bottom: 9px;
}

.mark__arrow {
  &:hover {
    --el-fonts--icon-color: #{$brand-color};
  }
}

.mark__arrow--active {
  transform: rotate(180deg);
  --el-fonts--icon-color: #{$brand-color};
}
