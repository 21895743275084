@import "../../../assets/scss/shared/index";

.modal-invitations {
  width: 1180px;
  max-width: 100%;
  //max-width: calc(100vw - 130px);
}

.modal-invitations__title {
  margin-bottom: 30px;
}

.modal-invitations__fields {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 20px;
  align-items: flex-end;
}

.modal-invitations__attach {
  display: flex;
  align-items: flex-end;
  grid-gap: 20px;
  margin-top: 30px;
  padding-top: 10px;
  border-top: 1px solid $border-light-color;
  flex-wrap: wrap;
}

.modal-invitations__attach-fields {
  flex: auto;

  @include sm() {
    width: 100%;
  }
}

.modal-invitations__attach-field {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 20px;
  position: relative;
  border-bottom: 1px solid $border-light-color;
  min-height: 44px;
  align-items: center;
}

.modal-invitations__attach-icon {
  position: absolute;
  top: 16px;
  right: 20px;
  cursor: pointer;

  &:hover {
    --el-fonts--icon-color: #{$outline-color};
  }
}

.modal-invitations__attach-field--label {
  --el-fonts--text-color: #{$label-color};
}

.modal-invitations__attach-email {
  display: flex;
  align-items: flex-start;
}
