@import "../../assets/scss/shared/index";

.exist-time {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  text-align: left;
  align-items: center;
  flex-wrap: wrap;

  @include less() {
    flex-direction: column-reverse;
    align-items: flex-start;
    grid-gap: 10px;
  }

  > .text--eject {
    width: #{'min(160px, 100%)'};
    margin-right: auto;
    padding-right: $default-offset;
  }
}

.exist-time__field {
  display: flex;
  align-items: center;

  > input {
    color: $text-color;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    height: 24px;
    outline: none;
    border: 0;
    width: 43px;
    border-bottom: 1px solid $accent-color;
    text-align: center;
    @include placeholder(opacity, .7);
    @include placeholder(color, $label-color);

    + div {
      margin-left: 8px;
    }
  }
}

.exist-time__input {
  background-color: $input-bg-color;
  box-shadow: 0px 4px 30px 7px rgba(0, 0, 0, 0.05);
  min-height: 44px;
  outline: none;
  flex: 1 1 #{'min(290px, calc(100% - 160px))'};
  border-radius: $block-radius;
  display: flex;
  align-items: center;
  padding: 0 15px;
  position: relative;

  @include less() {
    width: 100%;
  }

  &:hover {
    .exist-time__close {
      opacity: 1;
    }
  }
}

.exist-time__close {
  position: absolute;
  right: 11px;
  top: 11px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  cursor: pointer;
  opacity: 0;
}
