@import "../../../assets/scss/shared/index";

.support {
  max-width: 100%;
  width: 560px;
}

.support__close {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  padding: 10px;
  box-sizing: content-box;
  margin: -10px;

  @include xs() {
    top: 10px;
    right: 10px;
  }
}

.support__title {
  margin-bottom: 30px;
  font-size: 20px;

  @include xs() {
    margin-bottom: 10px;
  }
}

.support__error-platform {
  margin-bottom: 10px;
}

.support__description {
  margin-bottom: 30px;
  color: $label-color
}

.support__description-problem {
  margin-bottom: 30px;
}

.support__link-page {
  margin-bottom: 30px;
}

.support__attach-file {
  padding: 3px 70px;
  margin-bottom: 30px;
  position: relative;
}

.support__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.support__footer .support__send-data {
  padding: 3px 70px;
}

.toggle--primary__switch {
  width: 21px;
  height: 21px;
}

.support__buttons-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  font-size: 0;
}

.support__file-info {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  align-items: center;
}

.support__file-remove {
  width: 92%;
  background: #FAFBFC;
  border-radius: 6px;
  padding: 12px 15px;
}

.support__file-trash {
  padding: 9px;
  background: #FAFBFC;
  border-radius: 6px;
  cursor: pointer;
}

.grecaptcha-badge {
  visibility: hidden;
}

//iframe {
//  z-index: 100;
//  top: 50%;
//  right: 40%;
//}//TODO [Thunk]: grecaptcha ??
